// Grid system
.main,.tribe-events-pg-template {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
  .narrower-template &,.single-post & {
    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
.container-full {
  @include media-breakpoint-only(xs) {
    padding-left: $grid-gutter-width-base;
    padding-right: $grid-gutter-width-base;
  }
}
