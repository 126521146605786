header.banner {
  @extend .bg-black;
  @extend .shadow;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 100;
  &>.container-fluid {
    @include media-breakpoint-up(sm) {
      padding: 0 $grid-gutter-width;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 $grid-gutter-width*1.5;
    }
  }
  .logged-in.admin-bar & {
    top: 32px;
    @media screen and (max-width: 782px) {
      top: 46px;
    }
  }
  &>.container-full:first-child {
    &>.row {
      align-items: center;
    }
  }
  .logo {
    height: $header-height;
    @include media-breakpoint-up(lg) {
      height: $header-height-lg;
    }
  }
  .brand {
    display: block;
    max-width: 140px;
    position: relative;
    z-index: 100;
    @include media-breakpoint-up(lg) {
      max-width: 268px;
    }
    img {
      @extend .shadow;
      position: absolute;
      width: 100%;
      max-width: 100px;
      @include media-breakpoint-up(lg) {
        max-width: 170px;
      }
    }
  }
  .nav-overview {
    line-height: 1;
    ul {
      justify-content: flex-end;
      li {
        font: $font-size-sm $font-family-serif;
        text-transform: uppercase;
        a {
          @include hover-focus {
            color: $link-hover-color-nav;
          }
        }
      }
    }
  }
  .nav-trigger {
    text-align: right;
  }
  .navbar-toggler {
    color: $white;
    padding: 5px;
    margin: 0;
    border: none;
    font-size: $font-size-h2;
    cursor: pointer;
    align-self: flex-end;
    @include hover-focus {
      color: $link-hover-color-nav;
    }
    &:focus {
      outline: 2px solid $brand-primary;
    }
    .fa-bars {
      display: none;
    }
    &.ready,&.collapsed {
      .fa-bars {
        display: inline-block;
      }
      .fa-times {
        display: none;
      }
    }
  }
}
#navPrimary {
  background-color: rgba($black,.75);
  z-index: 99;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  top: $header-height;
  left: 0;
  @include media-breakpoint-up(lg) {
    top: $header-height-lg;
  }
  #menu-primary-navigation {
    @extend .list-normalize;
    text-align: right;
    text-transform: uppercase;
    margin: $grid-gutter-width 0;
    @include media-breakpoint-up(lg) {
      margin: $grid-gutter-width*1.5 0;
    }
    @include media-breakpoint-up(xl) {
      margin: $grid-gutter-width*2 0;
    }
    &>.menu-item {
      font: $font-size-md $font-family-serif;
      margin-bottom: .75em;
      &>a {
        display: block;
        color: $white;
        text-decoration: none;
        @include hover-focus {
          color: $gray-lighter;
        }
      }
      .sub-menu {
        @extend .list-normalize;
        font: .9rem $font-family-condensed;
        a {
          display: block;
          color: $gray-lighter;
          font-weight: $font-weight-base;
          text-decoration: none;
          @include hover-focus {
            color: $gray-light;
          }
        }
      }
    }
  }
  &.collapse {
    display: none !important;
    &.show {
      height: 100vh;
      display: block !important;
//             min-height: 100vh;
    }
  }
}
