/* Grid settings - basic
  --------------------------------------- */

$grid-gutter-width-base:  20px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      24px;

$main-sm-columns:         12;
$sidebar-sm-columns:      4;

$header-height:           60px;
$header-height-lg:        100px;

/* COLORS
  --------------------------------------- */
$red:                   #B31528;
$blue:                  #003B7C;
$orange:                #F26520;
$black:                 #000;
$black-near:            #404040;
$white:                 #fff;
$white-near:            #f2f2f2;

$gray:                  #656565;
$gray-dark:             #707070;
$gray-light:            #989898;
$gray-lighter:          #d1d1d1;

$brand-primary:         $red;
$brand-secondary:       $black;

$body-bg:               $white;
$body-color:            $black-near;

$link-color:            $brand-primary;
$link-decoration:       underline;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-hover-color-nav:  $gray-light;


/* FONTS
  --------------------------------------- */
$font-family-serif:     "Roboto Slab", serif;
$font-family-sans:      "Roboto", Arial, sans-serif;
$font-family-condensed: "Roboto Condensed", "Arial Narrow", sans-serif;
$font-family-base:      $font-family-sans;

$font-size-base:      1rem;        // 24 /
$font-size-md:        1.25rem;     // 30 /
$font-size-lg:        1.3333rem;   // 32 /
$font-size-xl:        1.5rem;      // 36 /
$font-size-sm:        .83333rem;   // 20 /
$font-size-xs:        .75rem;      // 18 /
$font-size-2xs:       .66666rem;   // 16 /

$font-size-h1:        2rem;        // 48 /
$font-size-h2:        1.6666rem;   // 40 /
$font-size-h3:        1.5rem;      // 36 /
$font-size-h4:        1.5rem;      // 36 /
$font-size-h5:        1.1666rem;   // 28 /
$font-size-h6:        1rem;        // 24 /

$line-height-base:    1.5;

$font-weight-normal:  400;
$font-weight-base:    $font-weight-normal;
$font-weight-bold:    700;
$font-weight-black:   900;

$headings-font-family:   $font-family-serif;
$headings-font-weight:   $font-weight-bold;
$headings-line-height:   1.1;
$headings-color:         $body-color;
$headings-color-alt:     $brand-primary;




/* BUTTONS
  --------------------------------------- */
$btn-padding-x:                  .5rem;
$btn-padding-y:                  .25rem;
$btn-line-height:                1;
$btn-font-weight:                $font-weight-base;
$btn-font-size:                  $font-size-sm;
$btn-font-size-lg:               $font-size-h2;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

$btn-primary-color:              $white;
$btn-primary-bg:                 $black;
$btn-primary-border:             $btn-primary-bg;
$btn-primary-color-hover:        $white;
$btn-primary-bg-hover:           $black-near;

$btn-secondary-color:            $white;
$btn-secondary-bg:               $brand-primary;
$btn-secondary-border:           $brand-primary;
$btn-secondary-color-hover:      $white;
$btn-secondary-bg-hover:         $black-near;


/* FORMS
  --------------------------------------- */
$input-padding-x:                .3rem;
$input-padding-y:                .25rem;
$input-line-height:              1;

$input-bg:                       $white;
$input-bg-disabled:              $gray-lighter;

$input-color:                    $gray-dark;
$input-border-color:             $gray;
$input-btn-border-width:         1px;
$input-box-shadow:               none;

$input-border-radius:            0;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-bg-focus:                 $input-bg;
$input-border-focus:             $gray-dark;
$input-box-shadow-focus:         $input-box-shadow;
$input-color-focus:              $input-color;

$input-color-placeholder:        $gray-light;
$input-font-size:                $font-size-sm;
