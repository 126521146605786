section.footer-ctas {
  @extend .primary-color-block;
  font-family: $font-family-condensed;
  padding: $grid-gutter-width*.75 0;
  margin-bottom: $grid-gutter-width*.75;
  @include media-breakpoint-up(md) {
    margin-bottom: $grid-gutter-width;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width*1.75;
    padding: $grid-gutter-width 0;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width*2.25;
    padding: $grid-gutter-width*1.75 0;
  }
  h2,h3 {
    font-size: $font-size-h5;
    margin-bottom: 0;
    text-transform: uppercase;
    @include media-breakpoint-only(md) {
      font-size: $font-size-h6;
    }
  }
  .strapline {
    @extend .sr-only;
    font-style: italic;
    margin-bottom: 0;
    font-size: $font-size-xs;
    line-height: 1.2;
  }
  .col-12 {
    @include media-breakpoint-down(sm) {
      margin-bottom: $grid-gutter-width;
    }
  }
  .mc-embedded-subscribe-form {
    margin-top: .5rem;
  }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]) {
    border: none;
  }
  .social-links {
    margin-top: .5rem;
    ul {
      @extend .list-normalize;
      li {
        display: inline-block;
        margin-right: $grid-gutter-width*.75;
        @include media-breakpoint-between(md,lg) {
          margin-right: $grid-gutter-width*.5;
        }
        a {
          font-size: $font-size-xl;
          display: block;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-color: $black;
          text-align: center;
          line-height: 70px;
          @include media-breakpoint-only(md) {
            font-size: $font-size-sm;
            width: 35px;
            height: 35px;
            line-height: 35px;
          }
          @include media-breakpoint-up(lg) {
            font-size: $font-size-lg;
            width: 50px;
            height: 50px;
            line-height: 50px;
          }
          @include media-breakpoint-only(xl) {
//             font-size: $font-size-lg;
            width: 55px;
            height: 55px;
            line-height: 55px;
          }
          @include hover-focus {
            background-color: $white;
            color: $brand-primary;
          }
        }
      }
    }
  }
}
footer.content-info {
  @extend .bg-black;
  font-family: $font-family-condensed;
  text-transform: uppercase;
  padding: 0 0 $grid-gutter-width*.75 0;
  @include media-breakpoint-up(md) {
    padding: 0 0 $grid-gutter-width 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 0 $grid-gutter-width*1.75 0;
  }
  @include media-breakpoint-up(md) {
    padding: 0 0 $grid-gutter-width*2.25 0;
  }
  a {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    text-decoration-color: rgba($white,.5);
    @include hover-focus {
      text-decoration-color: $link-color;
      text-decoration-style: solid;
    }
  }
  h2,h3 {
    color: $white;
    font-size: $font-size-h5;
    margin-bottom: .5em;
    @include media-breakpoint-only(md) {
      font-size: $font-size-h6;
    }
  }
  .contact-info {
    line-height: 1.1;
    font-weight: $font-weight-bold;
    img {
      margin-bottom: $grid-gutter-width*1.25;
    }
    p {
      margin-bottom: .65em;
    }
/*
    a.address {
      color: $brand-primary;
      @include hover-focus {
        color: rgba($white,.9);
      }
    }
*/
  }
  .copyright {
    margin-top: $grid-gutter-width;
    color: $gray-light;
    line-height: 1.1;
    font-size: $font-size-xs;
    .tagline {
      margin-bottom: 0;
    }
  }
  .quick-links {
    font-size: $font-size-xs;
    nav {
      ul {
        @extend .list-normalize;
        margin-bottom: 1rem;
        columns: 2;
      }
    }
  }
  .contact-info,.hours,.quick-links {
    @include media-breakpoint-down(sm) {
      margin-bottom: $grid-gutter-width*2;
    }
  }
}

