.table-hours {
  font: $font-size-sm $font-family-condensed;
  text-transform: uppercase;
  tr {
    td {
      border-top: none;
      padding: 2px;
      &:first-child {
//         padding-left: 0;
      }
      &:last-child {
//         padding-right: 0;
        text-align: right;
      }
    }
    &.current {
      @extend .bg-primary;
    }

  }
}
