.btn {
  cursor: pointer;
  padding: $btn-padding-y $btn-padding-x;
  text-decoration: none;
  text-transform: uppercase;
  line-height: $btn-line-height;
  font: $btn-font-weight $btn-font-size $headings-font-family;
  border-radius: 0;
  border: 1px solid $btn-primary-border;
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  letter-spacing: .04em;
  @include hover-focus {
    color: $btn-primary-color-hover;
    background-color: $btn-primary-bg-hover;
    border-color: $btn-primary-bg-hover;
  }
  &.btn-lg {
    font-size: $font-size-h2;
    padding: .5rem 1rem;
  }
  &.btn-trans {
    background-color: rgba($btn-primary-bg,.65);;
    @include hover-focus {
      color: $btn-primary-color-hover;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-bg;
    }
  }
  &.btn-secondary {
    border-color: $btn-secondary-border;
    color: $btn-secondary-color;
    background-color: $btn-secondary-bg;
    @include hover-focus {
      color: $btn-secondary-color-hover !important;
      background-color: $btn-secondary-bg-hover;
      border-color: $btn-secondary-bg-hover;
    }
  }
}
