// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
label {
  &.small-label {
    font-family: $font-family-condensed;
    text-transform: uppercase;
    font-size: $font-size-2xs;
    margin: 0 0 .2em;
    display: block;
  }
}

.form-control {
  font-family: $font-family-condensed;
  font-size: $input-font-size;
  letter-spacing: .05em;
  text-transform: none !important;
  &::-webkit-input-placeholder {
    font-style: italic;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-style: italic;
  }
  &::-moz-placeholder {  /* Firefox 19+ */
    font-style: italic;
  }
  &:-ms-input-placeholder {
    font-style: italic;
  }
  &:focus {
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: transparent;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
  }
}
.beerfinder-simple {
  margin-top: .5rem;
}
.beerfinder-detail {
  h2,h3 {
    font-size: $font-size-h3;
    margin-bottom: $grid-gutter-width;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }
  select {
    @extend .black-select;
    padding-right: 45px;
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }
}
.selectwrap {
  margin-bottom: $grid-gutter-width*1.5;
  @include media-breakpoint-up(md) {
    padding-right: 16px;
  }
  #formselect {
    @extend .black-select;
  }
}

.gform_wrapper {
  .gform_heading {
    @extend .sr-only;
  }
  .field_sublabel_below .ginput_complex.ginput_container label,
  .field_sublabel_below div[class*=gfield_time_].ginput_container label {
    color: $gray;
    font-size: $font-size-xs !important;
    margin-top: 0 !important;
  }

  .top_label .gfield_label {
    margin-bottom: 0;
    font: $font-weight-bold $font-size-sm $font-family-condensed;
    color: $black;
    text-transform: uppercase;
  }
  .field_sublabel_below div label {
  }
  .top_label div.ginput_container {
    margin-top: 0 !important;
  }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),textarea,select {
    @extend .form-control;
    text-transform: none !important;
  }
  input[type=submit] {
    @extend .btn;
  }
  select {
    font-family: $font-family-condensed !important;
    font-size: $input-font-size !important;
    letter-spacing: .05em;
  }
  .gform_submission_error {
    padding: 8px;
  }
}
