.tiles {
  .tile {
    margin-bottom: $grid-gutter-width;
    header {
      margin-bottom: $grid-gutter-width;
      h2 {
        @extend .tile-title;
        margin-bottom: $grid-gutter-width*.5;
        font-size: $font-size-lg;
        .mets & {
          @extend .bg-blue;
          background-image: none;
        }
      }
      p {
        margin: 0;
        line-height: 1.35;
        font-size: $font-size-xs;
        font-style: italic;
      }
    }
    figure {
      margin-bottom: 0;
      @extend .bg-black;
      .mets & {
        @extend .bg-orange;
      }
      img {
        @include transition($link-transition);
      }
      a {
        display: block;
        border: 3px solid $black;
        @include hover-focus {
          border-color: $brand-primary;
          img {
            opacity: .9;
          }
        }
      }
    }
  }
  &.cta-tiles {
    .tile {
      h2 {
        display: block;
        padding: $grid-gutter-width*.25 $grid-gutter-width*.5;
        font-size: $font-size-h5;
        @include media-breakpoint-up(md) {
          font-size: $font-size-sm;
        }
        @include media-breakpoint-up(xl) {
          font-size: $font-size-h6;
        }
      }

    }
  }
}

