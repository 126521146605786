.list-normalize {
  margin: 0;
  padding: 0;
  list-style: none;
}
.block-padding {
  padding-top: $grid-gutter-width*1.5;
  padding-bottom: $grid-gutter-width*1.5;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width*1.75;
    padding-bottom: $grid-gutter-width*1.75;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-width*2;
    padding-bottom: $grid-gutter-width*2;
  }
  @include media-breakpoint-up(xl) {
    padding-top: $grid-gutter-width*2.5;
    padding-bottom: $grid-gutter-width*2.5;
  }
}
.bg-black {
  background-color: $black;
  color: $white;
  a {
    @include transition($link-transition);
    color: $white;
    text-decoration: none;
    @include hover-focus {
      color: $brand-primary;
    }
  }
}

.bg-black-80 {
  @extend .bg-black;
  background-color: rgba($black,.8);
}
.bg-white-near {
  background-color: $white-near;
}

.bg-primary {
  background-color: $brand-primary;
  color: $white;
  a {
    color: $white;
  }
  h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    color: $white;
  }
}
.bg-texture {
  background-image: url(../images/bg-texture.png);
  background-repeat: repeat;
  background-position: center center;
  background-size: 1000px auto;
}
.primary-color-block {
  @extend .bg-primary;
  @extend .bg-texture;
  padding: $grid-gutter-width*.75 0;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width 0;
  }
  @include media-breakpoint-up(xl) {
    padding: $grid-gutter-width*1.75 0;
  }
}


.bg-texture-black {
  background: $black url(../images/bg-texture-black.png) repeat  left center;
}
.bg-blue {
  background-color: $blue;
  color: $white;
  a {
    @include transition($link-transition);
    color: $white;
    text-decoration: none;
    @include hover-focus {
      color: $orange;
    }
  }
}
.bg-orange {
  background-color: $orange;
  color: $blue;
  a {
    @include transition($link-transition);
    color: $blue;
    text-decoration: none;
    @include hover-focus {
      color: $body-color;
    }
  }
}
.shadow {
  box-shadow: 0px 9px 6px 0px rgba($black,.35);
}
.tile-title {
  @extend .bg-texture-black;
  width: auto;
  display: inline-block;
  color: $white !important;
  text-transform: uppercase;
  border:1px solid $gray;
  padding: $grid-gutter-width*.25 $grid-gutter-width;
}
.tile-title-tilt {
  @extend .tile-title;
  margin-bottom: $grid-gutter-width*1.5;
  transform: rotate(-2deg);
  border-color: transparent;
}
.black-select {
  @extend .bg-black;
  border-color: transparent;
  -webkit-appearance: none;
  height: calc(1.5rem + 4px) !important;
  background: $black url(../images/select-arrow.png) no-repeat right center;
  background-size: 45px auto;
  &:focus {
    @extend .bg-black;
    border-color: transparent;
  }
}
