.tribe-events-single-event-title {
  color: $brand-primary;
}
.tribe-events-view-loader,
header.tribe-events-header {
  @extend .sr-only;
}
.tribe-events-single {
  .event-sidebar {
    text-align: right;
    color: $black;
    font-family: $font-family-condensed;
    .event-sidebar-wrap {
      @include media-breakpoint-up(md) {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 110px;
      }
      @include media-breakpoint-up(lg) {
        top: 150px;
      }
      @include media-breakpoint-up(xl) {
        top: 120px;
      }
    }
    .event-date-info {
      @extend .bg-texture-black;
      letter-spacing: .04em;
      text-transform: uppercase;
      font: $headings-font-weight 3rem $headings-font-family;
      line-height: 1;
      padding: $grid-gutter-width*.5;
      color: $white;
      span {
        display: block;
        font-size: $font-size-h5;
      }
    }
    .event-date-details {
      margin: $grid-gutter-width*.5 0;
      padding: 0 $grid-gutter-width*.5 0 0;
      border-right: 2px solid $brand-primary;
      line-height: 1.3;
      font-size: $font-size-base;
      span {
        font-size: $font-size-base;
      }
      .tribe-events-schedule__all-day, .tribe-events-schedule__separator, .tribe-events-schedule__timezone {
        color: $black;
      }
      h3 {
        font-size: $font-size-h4;
        margin: $grid-gutter-width*.5 0 0;
        font-family: $font-family-base;
        text-transform: uppercase;
        color: $black;
      }
      .tribe-block__venue__name {
        display: block;
      }
      .tribe-block__venue__address,.tribe-block__venue__phone {
        color: $black;
        margin: 0;
      }
      a.directions {
        color: $brand-primary;
        font-size: $font-size-xs;
        font-weight: $font-weight-base;
      }
    }
    .event-tickets {
      .tribe-block__event-price {
        display: block;
      }
    }
    .event-website {
      .btn {
        width: 100%;
      }
    }
    h4.share-title {
      @extend .bg-texture-black;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      font-size: $font-size-h5;
      margin-top: $grid-gutter-width*.5;
      padding: 5px;
    }
    .addtoany_list {
      display: block;
      text-align: center;
      margin-bottom: $grid-gutter-width;
      a {
        @extend .bg-black;
        margin: 0 $grid-gutter-width*.5;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding-top: 5px;
        &:not(.addtoany_special_service)>span {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
        @include media-breakpoint-only(md) {
          width: 40px;
          height: 40px;
          line-height: 40px;
          margin: 0 $grid-gutter-width*.3;
          padding-top: 3px;
          &:not(.addtoany_special_service)>span {
            width: 30px;
            height: 30px;
            line-height: 30px;
          }
        }
        @include media-breakpoint-up(xl) {
          width: 65px;
          height: 65px;
          line-height: 65px;
          padding-top: 9px;
          &:not(.addtoany_special_service)>span {
            width: 45px;
            height: 45px;
            line-height: 45px;
          }
        }
      }
    }
  }
  .event-content {
    .tribe-events-schedule,.tribe-block__venue,.tribe-block__events-link {
      @extend .sr-only;
    }
  }
}
.tribe-events-related-location {
  text-align: center;
  h2.tribe-block__related-events__title {
    @extend .tile-title-tilt;
  }
  ul.tribe-related-events {
    @extend .row;
    list-style: none;
    padding: 0;
    text-align: left;
    li {
      @extend .col-12;
      @extend .col-sm-6;
      @extend .col-lg-4;
      margin-bottom: $grid-gutter-width;
      .tribe-related-events-thumbnail {
        @extend .bg-black;
        margin-bottom: $grid-gutter-width*.5;
        a {
          img {
            @include transition($link-transition);
            @extend .img-fluid;
          }
          @include hover-focus {
            img {
              opacity: .9;
            }
          }
        }
      }
      .tribe-related-event-info {
        font: $font-weight-bold $font-size-sm $font-family-condensed;
        text-transform: uppercase;
        .tribe-related-events-title {
          font-size: $font-size-h5;
          text-transform: none;
          margin-bottom: $grid-gutter-width*.25;
        }
      }
    }
  }
}
.post-type-archive-tribe_events {
  color: $black;
  .tribe-events-view--list {
    @extend .col-12;
  }


  .tribe-events-l-container {
    padding: 0 !important;
  }
  .tribe-events-calendar-list {
    .tribe-events-calendar-list__month-separator {
      @extend .sr-only;
    }
    .tribe-events-calendar-list__event-row {
      margin: $grid-gutter-width*3 0;
      .tribe-events-calendar-list__event-date-tag {
        @extend .sr-only;
        display: none;
      }
      .tribe-events-calendar-list__event-wrapper {
        display: block;
        padding: 0;
        .tribe-events-calendar-list__event {
          @extend .row;
          flex-direction: row;
          .tribe-events-calendar-list__event-featured-image-wrapper {
            @extend .col-12;
            @extend .col-md-4;
          }
          .tribe-events-calendar-list__event-details {
            @extend .col-12;
            @extend .col-md-8;
            font-size: $font-size-sm;
            .tribe-events-calendar-list__event-header {
              .tribe-events-calendar-list__event-datetime-wrapper {
                @extend .bg-texture-black;
                color: $white;
                font: $font-weight-bold $font-size-h5 $font-family-base;
                padding: 5px;
                text-transform: uppercase;
              }
            }
            .tribe-events-calendar-list__event-description {
              margin-top: $grid-gutter-width*.5;
            }
            .venue-data {
              font-size: $font-size-xs;
              margin: $grid-gutter-width*.5 0 $grid-gutter-width;
              padding: 0 0 0 $grid-gutter-width*.5;
              border-left: 2px solid $brand-primary;
              h3 {
                font-size: $font-size-h6;
                text-transform: uppercase;
                margin: $grid-gutter-width*.5 0 0;
                font-family: $font-family-base;
                text-transform: uppercase;
                color: $black;
              }
              .tribe-events-calendar-list__event-venue-title {
                font-weight: $font-weight-bold;
              }
            }
            .btn {
              @extend .btn;
              @extend .btn-secondary;
            }
          }
        }
      }
    }
  }
}
.tribe-events {
  .tribe-events-c-nav {
    border-top: 5px solid $black;
    border-bottom: 5px solid $black;
    padding: $grid-gutter-width*.5 0 !important;
    margin-top: $grid-gutter-width*2 !important;
    ul.tribe-events-c-nav__list {
      li {
        a {
          display: block;
          color: $black;
          text-decoration: none;
          font-weight: $headings-font-weight;
          font-family: $headings-font-family;
          text-transform: uppercase;
          @include hover-focus {
            color: $brand-primary;
          }
        }
        button[disabled] {
          display: none !important;
        }
        &.tribe-events-c-nav__list-item--prev {
        border-right: 1px solid $black;
        }
        &.tribe-events-c-nav__list-item--next {
          border-left: 1px solid $black;
        }
      }
    }
  }
}
