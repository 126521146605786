html {
  font-size: 18px;
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 24px;
  }
}
body {
  letter-spacing: .02em;
  padding-top: $header-height;
  @include media-breakpoint-up(lg) {
    padding-top: $header-height-lg;
  }
}
.wrap {
  margin-top: $grid-gutter-width*1.5;
  margin-bottom: $grid-gutter-width*3;
  @include media-breakpoint-up(lg) {
    margin-top: $grid-gutter-width*2.5;
    margin-bottom: $grid-gutter-width*4;
  }
  @include media-breakpoint-up(xl) {
    margin-top: $grid-gutter-width*3;
    margin-bottom: $grid-gutter-width*5;
  }
  .home & {
    margin-bottom: $grid-gutter-width*1.5;
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width*2.5;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $grid-gutter-width*3;
    }
  }
}
p,ol.ul {
  margin-bottom: 1.5rem;
}
a {
  font-weight: $font-weight-bold;
  @include transition($link-transition);
  &#skip-link {
    position:fixed;
    left:-999px;
    top: 0;
    z-index:1001;
    width:1px;
    height:1px;
    overflow:hidden;
    &:active, &:focus {
      top:8px;
      left: 8px;
      width: auto;
      height: auto;
      overflow:auto;
    }
  }
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  letter-spacing: .02em;
}
h1,.h1,h3,.h3 {
  color: $headings-color-alt;
  .mets & {
    color: $blue;
  }
}
.hidden {
  display: none !important;
}

