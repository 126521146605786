// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}
.figure-caption, .wp-caption-text {
  color: $gray;
  font-size: $font-size-sm;
}
.wp-block-quote,blockquote {
  margin: 0 $grid-gutter-width 1.5rem;
  margin-bottom: 1.5em;
  padding: $grid-gutter-width/2 $grid-gutter-width;
  border-left: 15px solid $brand-primary;
  font-style: italic;
  font-size: $font-size-lg;
  font-weight: $font-weight-black;
  p {
    margin-bottom: .25rem;
  }
  cite {
    color: $gray-light;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    font-style: normal;

  }
}
// Captions
.wp-block-image {
  margin-bottom: 1.5rem;
  figcaption {
    font-size: $font-size-sm;
    margin: 1em $grid-gutter-width 0;
    padding: $grid-gutter-width/2 $grid-gutter-width;
    border-left: 5px solid $brand-primary;
    color: $gray;
    font-style: italic;
  }
}
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
