.page-header {
  min-height: 600px;
  height: calc(100vh - #{$header-height});
  @include media-breakpoint-up(md) {
//     min-height: 700px;
  }
  @include media-breakpoint-up(lg) {
//     min-height: 800px;
    height: calc(100vh - #{$header-height-lg});
  }
  @include media-breakpoint-up(xl) {
//     min-height: 900px;
  }
  max-height: 1024px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  &.thin-header {
    min-height: 100px;
    height: 100px;
    @include media-breakpoint-up(sm) {
      height: 150px;
    }
    @include media-breakpoint-up(md) {
      height: 180px;
    }
    @include media-breakpoint-up(lg) {
      height: 220px;
    }
    @include media-breakpoint-up(xl) {
      height: 250px;
    }
  }
  section {
    @extend .bg-black-80;
    .mets & {
      background-color: rgba($blue,.9);
    }
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1rem 0;
    h1 {
      text-transform: uppercase;
      text-align: center;
      color: $white;
      letter-spacing: .15em;
      margin: 0;
      small {
        display: block;
        font-size: $font-size-h3;
      }
    }
    .taproom-header {
      .contact-info {
        text-transform: uppercase;
        font-size: $font-size-sm;
        p {
          margin-bottom: .5rem;
        }
        h3 {
          font-size: $font-size-h5;
          color: $white;
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
      }
      .hours-title {
        @extend .sr-only;
      }
      .table-hours {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
      .tour-info {
        @include media-breakpoint-down(md) {
          margin-top: $grid-gutter-width;
        }
      }
      .tour-title {
        font-weight: $font-weight-bold;
        line-height: 1.25;
        margin-bottom: .5rem
      }
      .tour-content {
        font-size: $font-size-sm;
        line-height: 1.25;
        margin-bottom: .5rem
      }
      .tour-footnote {
        font-size: $font-size-2xs;
        line-height: 1.25;
        margin-bottom: .5rem
      }
    }
    time {
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin-top: .35rem;
    }
  }
  .btn-hero {
    position: absolute;
    bottom: $grid-gutter-width*3;
    left: 50%;
    transform: translateX(-50%);
    white-space: normal !important;
    box-shadow: 0 5px 10px rgba($black,.5);
    border-color: transparent;
  }
}
.beerfinder-bar {
  @extend .bg-primary;
  @extend .bg-texture;
  padding: 1rem 0;
//   @include media-breakpoint-up(lg) {
    position: fixed;
    position: sticky;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
//   }
  @include media-breakpoint-only(lg) {
    h3 {
      font-size: $font-size-h5;
    }
  }
}
.thin-hero {
  text-align: center;
  margin-top: $grid-gutter-width*2;
  h1 {
    @extend .tile-title-tilt;
  }
}
section.beerslide-wrap {
  @extend .bg-black;
  padding: $grid-gutter-width 0;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 400px;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width*3.8 0 $grid-gutter-width*2;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-width*2;
  }
  h2 {
    @extend .tile-title-tilt;
    margin-bottom: $grid-gutter-width*2;
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width*3;
    }
  }
  .beerslide {
/*
    @extend .row;
    &>div {
      @extend .col-4;
    }
*/
  }
}
section.block {
  @extend .block-padding;
}
section.block-limited-beer,
section.block-togo-beer {
  padding-top: 0;
  color: $gray;
  font-weight: $font-weight-bold;
  h2 {
    @extend .tile-title;
    margin-bottom: $grid-gutter-width*1.5;
  }
  .cta {
    @extend .col-12;
    @extend .col-md-6;
    @extend .col-lg-4;
    text-align: left;
    margin-bottom: $grid-gutter-width;
    a {
      color: $body-color;
      text-decoration: none;
      figure {
        @extend .bg-black;
        margin-bottom: $grid-gutter-width*.5;
        img {
          @include transition($link-transition);
        }
      }
      h3 {
        @include transition($link-transition);
        font-size: $font-size-h5;
        color: $body-color;
        margin-bottom: 0;
      }
      span {
        display: block;
        font: $font-weight-bold $font-size-sm $font-family-condensed;
        text-transform: uppercase;
      }
      @include hover-focus {
        figure {
          img {
            opacity: .9;
          }
        }
        h3 {
          color: $link-color;
        }
      }
    }
  }
}
section.block-limited-beer {
  text-align: center;
  h2 {
    @extend .tile-title-tilt;
    margin-bottom: $grid-gutter-width*1.5;
  }
}
section.block-togo-beer {
  margin-top: $grid-gutter-width;
  h2 {
    font-size: $font-size-h5;
    margin-bottom: $grid-gutter-width;
  }
}
section.block-taplist {
  @extend .bg-texture;
  @extend .bg-white-near;
  h2 {
    @extend .tile-title;
    margin-bottom: $grid-gutter-width;
  }
  .taplist {
    @extend .h3;
    @extend .row;
    font-size: $font-size-md;
    color: $body-color;
    list-style: none;
    padding: 0;
    li {
      @extend .col-6;
      @extend .col-lg-4;
      margin-bottom: $grid-gutter-width;
    }
    span {
      font: $font-weight-bold $font-size-base $font-family-condensed;
      display: block;
      text-transform: uppercase;
    }
  }
  .row.beerstogo {
    text-align: center;
    justify-content: center;
    h2 {
      @extend .tile-title-tilt;
      font-size: $font-size-h1;
    }
    .cta {
      @extend .col-12;
      @extend .col-md-6;
      @extend .col-lg-4;
      text-align: left;
      margin-bottom: $grid-gutter-width;
      a {
        color: $body-color;
        text-decoration: none;
        figure {
          @extend .bg-black;
          margin-bottom: $grid-gutter-width*.5;
          img {
            @include transition($link-transition);
          }
        }
        h3 {
          @include transition($link-transition);
          font-size: $font-size-h5;
          color: $body-color;
          margin-bottom: 0;
        }
        span {
          display: block;
          font: $font-weight-bold $font-size-sm $font-family-condensed;
          text-transform: uppercase;
        }
        @include hover-focus {
          figure {
            img {
              opacity: .9;
            }
          }
          h3 {
            color: $link-color;
          }
        }
      }
    }
  }
}
section.block-map {
  padding: $grid-gutter-width*1.5 0 0;
  margin: 0 0 -9px;
  text-align: center;
  text-transform: uppercase;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $gray;
  line-height: 1.2;
  h2 {
    @extend .tile-title-tilt;
    font-size: $font-size-h1;
  }
}
.mobile-beer-finder {
  @extend .bg-primary;
  @extend .bg-texture;
  @extend .hidden-md-up;
  padding: $grid-gutter-width 0;
  h2,h3 {
    color: $white;
    font-size: $font-size-h5;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .strapline {
    font-style: italic;
    margin-bottom: 0;
    font-size: $font-size-xs;
    line-height: 1.2;
  }

}
main.main {
  h2,h3 {
    color: $brand-primary;
    letter-spacing: .06em;
    .mets & {
      color: $blue;
    }
  }
}
.beer-locations {
  text-align: center;
  .locations-title {
    @extend .tile-title-tilt;
  }
  .locations-detail {
    color: $gray;
    font-weight: $font-weight-bold;
  }
  ul.found {
    @extend .row;
    list-style: none;
    padding: $grid-gutter-width 0 0;
    text-align: left;
    li {
      @extend .col-6;
      @extend .col-lg-4;
      line-height: 1.25;
      font-weight: $font-weight-bold;
      font-family: $font-family-condensed;
      margin-bottom: $grid-gutter-width*1.5;
      h4 {
        font-size: $font-size-h5;
        margin: 0;
        text-transform: none;
      }
    }
  }
}
body.age-restriction {
  .age-gate-form {
    max-width: 660px;
    h2 {
      @extend .h5;
      text-transform: uppercase;
      color: $white;
    }
    button {
      @extend .btn;
      @extend .btn-secondary;
    }
  }
  .age-gate-heading.age-gate-logo {
    img {
      max-width: 250px;
      margin-bottom: $grid-gutter-width;
    }
  }
}
body.rentals {
  main.main {
    text-align: center;
  }
  .rental-info-block {
    text-align: left;
    font-size: $font-size-sm;
    aside {
      @extend .bg-black;
      padding: $grid-gutter-width;
      margin-bottom: $grid-gutter-width;
      font-size: $font-size-2xs;
      line-height: 1.3;
      h2 {
        font-size: $font-size-h5;
        color: $white;
        border-bottom: 3px solid $white;
        padding-bottom: 10px;
      }
      p {
        margin-bottom: .75em;
      }
    }
    article {
      margin-bottom: $grid-gutter-width;
      h2.rental-title {
        @extend .bg-primary;
        padding: 10px;
        text-transform: uppercase;
        text-align: center;
      }
      h2,h3 {
        text-align: center;
      }
      h2:not(.rental-title),h3 {
        font-size: $font-size-h4;
      }
      img {
        display: none;
      }
      &>ul {
        @extend .list-normalize;
        &>li {
          break-inside: avoid;
          font-weight: bold;
          margin-bottom: $grid-gutter-width/2;
          ul {
            font-weight: normal;
            font-size: $font-size-xs;
          }
        }
        @include media-breakpoint-up(md) {
          column-count: 2;
          column-gap: $grid-gutter-width;
        }
      }
    }
  }
}
.rental-tabs {
  font-size: $font-size-sm;
  text-align: left;
  ul.nav {
    @extend .bg-primary;
    justify-content: space-around;
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
    li {
      width: 25%;
      text-align: center;
      a {
        @extend .h6;
        margin: 0;
        line-height: 1;
        display: block;
        text-transform: uppercase;
        color: $white;
        padding: 10px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        @include hover-focus {
          background-color: rgba($black,.3);
          text-decoration: none;
        }
      }
      &.current {
        a {
          background-color: rgba($black,.3);
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      display: none !important;
      &.current {
        display: block !important;
      }
      @include media-breakpoint-down(sm) {
        display: block !important;
        margin-bottom: $grid-gutter-width*1.5;
      }
      .tab-title {
        background-color: $brand-primary;
        color: $white;
        padding: 5px 10px;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
      &>.row {
        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
        }
        .asides {
          aside {
            @extend .bg-black;
            padding: $grid-gutter-width*.75;
            margin-bottom: $grid-gutter-width;
            font-size: $font-size-xs;
            line-height: 1.3;
            h2 {
              font-size: $font-size-h3;
              text-transform: uppercase;
              color: $white;
              border-bottom: 10px solid $white;
              padding-bottom: 10px;
            }
            p {
              margin-bottom: .75em;
            }
          }
          article {
            font-size: $font-size-lg;
            p {
              @extend .h5;
              margin-bottom: 5px;
            }
            .btn {
              width: 100%;
            }
          }
        }
        .details {
          img {
            @extend .img-fluid;
            margin-top: 0;
          }
          h2 {
            text-transform: uppercase;
          }
          &>ul {
            @extend .list-normalize;
            margin-bottom: $grid-gutter-width;
            @include media-breakpoint-up(md) {
              columns: 2;
            }
            &>li {
              text-transform: uppercase;
              font-weight: $font-weight-bold;
              margin: 0;
              padding: $grid-gutter-width*.5;
              padding-top: 0;
              border-left: 2px solid $brand-primary;
              ul {
                text-transform: none;
                font-weight: $font-weight-base;
                padding-left: $grid-gutter-width*1.25;
                list-style: disc;
                font-size: $font-size-xs;
                li {
                  padding: 0;
                  border-left: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
