.blog {
  main.main {
    .hentry {
      @extend .col-12;
      @extend .col-md-6;
      margin-bottom: $grid-gutter-width*2;
      figure {
        @extend .bg-black;
        margin-bottom: $grid-gutter-width*.5;
        img {
          @extend .img-fluid;
          @include transition($link-transition);
          position: relative;
          @include media-breakpoint-up(md) {
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      h2 {
        font-size: $font-size-h3;
      }
      a {
        text-decoration: none;
        h2 {
          @include transition($link-transition);
        }
        time {
          display: block;
          color: $black;
          font-size: $font-size-sm;
          text-transform: uppercase;
        }
        @include hover-focus {
          h2 {
            color: $black;
          }
          figure {
            img {
              opacity: .9;
            }
          }
        }
      }
    }

  }
}
.posts-navigation {
  border-top: 5px solid $black;
  border-bottom: 5px solid $black;
  padding: $grid-gutter-width*.5 0;
  margin-top: $grid-gutter-width*2;
  .nav-links {
    display: flex;
    flex: 1;
//         justify-content: space-between;
    &>div {
      flex: 0 0 50%;
      max-width: 50%;
      a {
        display: block;
        color: $black;
        text-decoration: none;
        font-weight: $headings-font-weight;
        font-family: $headings-font-family;
        text-transform: uppercase;
        i {
        }
        @include hover-focus {
          color: $brand-primary;
        }
      }
      &.nav-next {
        text-align: right;
        border-left: 1px solid $black;
      }
      &.nav-previous {
        border-right: 1px solid $black;
      }
    }
  }
  .single-post & {
    width: 100%;
    margin-left: $grid-gutter-width*.5;
    margin-right: $grid-gutter-width*.5;
  }
}
.beer-details {
  figure {
    @extend .bg-black;
    margin-bottom: 0;
    padding-left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(md) {
      padding-right: 0;
      margin-bottom: $grid-gutter-width;
    }
    img {
      @extend .img-fluid;
      @extend .hidden-lg-up;
    }
  }
  article {
    @extend .block-padding;
    @extend .container-full;
    font-size: $font-size-sm;
    h1 {
      font-size: $font-size-h3;
    }
    @include media-breakpoint-only(sm) {
      margin-left: auto;
      margin-right: auto;
      max-width: 540px;
    }
    @include media-breakpoint-only(md) {
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 480px;
      padding-left: $grid-gutter-width*1.5;
    }
    @include media-breakpoint-up(xl) {
      max-width: 570px;
    }
    .beer-stats {
      @extend .list-normalize;
      font-size: $font-size-xs;
      padding: $grid-gutter-width/4 0 $grid-gutter-width/4 $grid-gutter-width;
      margin-left: $grid-gutter-width;
      border-left: 5px solid $brand-primary;
      .mets & {
        border-left-color: $orange;
      }
      li {
        margin-bottom: 1em;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
}

.single-beer {
  .beer-bar {
    @extend .primary-color-block;
    h2,h3 {
      font-size: $font-size-h5;
      margin-bottom: 0;
      text-transform: uppercase;
      line-height: 1.2;
      @include media-breakpoint-only(md) {
        font-size: $font-size-h6;
      }
    }
    .beer-availability,.beer-format {
      font: $font-weight-bold $font-size-h4 $font-family-condensed;
      text-transform: uppercase;
      line-height: 1.1;
    }
    .strapline {
      @extend .sr-only;
      font-style: italic;
      margin-bottom: 0;
      font-size: $font-size-xs;
      line-height: 1.2;
    }
    .col-12 {
      @include media-breakpoint-down(sm) {
        margin-bottom: $grid-gutter-width;
      }
    }
    .beer-searcher {
      @include media-breakpoint-only(md) {
        margin-top: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
      }
    }
  }
  .beer-slideshow {
    @extend .bg-black;
    min-height: $grid-gutter-width*3;
    position: relative;
    .beer-highlight {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      width: 100%;
      .highlight {
        background-color: rgba($white,.85);
        max-height: 580px;
        overflow-y: scroll;
        @include media-breakpoint-up(md) {
          max-height: 400px;
        }
        @include media-breakpoint-up(lg) {
          max-height: 540px;
        }
        @include media-breakpoint-up(xl) {
          max-height: 640px;
        }

        article {
          padding: $grid-gutter-width;
          color: $body-color;
          line-height: 1.4;
          font-size: $font-size-sm;
          div {
            p:last-child {
              margin-bottom: 0;
            }
          }
          h3 {
            text-transform: uppercase;
          }
          h4 {
            @extend .bg-texture-black;
            color: $white;
            font-size: $font-size-h5;
            font-family: $font-family-base;
            padding: 5px;
            text-transform: uppercase;
          }
          .btn {
            margin-top: .25rem;
          }
        }
      }
    }
    .slick-slide {
      img {
        width: 100%;
        @extend .img-fluid;
      }
    }
  }
}
